.carrito_container{
    width: 100%;
    margin-top: 50px;
    padding: 0 30px;
}

.carrito_portada{
    padding: 4.6rem 0 5rem;
    background-color: #ebebeb;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-image: url('../images/portada.jpg');
    text-align: center;
}

.empty_message{
    width: 100%;
    display: flex;
    justify-content: center;
}

.empty_message p{
    background-color: #7e0d10;
    text-align: center;
    font-size: 2rem;
    border-radius: 15px;
    width: fit-content;
    margin: 0;
    padding: 10px 60px;
    color: #ffffff;
}

.empty_message a{
    font-size: 1.8rem;
    color: inherit;
}

.list_car_items{
    display: flex;
    gap: .5rem;
    width: 100%;
}

.list_cart_prod{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 85%;
}

.cart_prod_container{
    display: flex;
    gap: 1.5rem;
    padding: 15px;
    border: 1px solid #d5d5d5;
    border-radius: 15px;
}

.cart_prod_container p{
    margin: 0;
    color: #000000;
}

.cart_prod_image_container{
    border-radius: 15px;
    overflow: hidden;
    background-color: blue;
    width: 20%;
}

.cart_prod_container img{
    width: 100%;
    height: 100%;
}

.cart_prod_desc_container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
}

.cart_prod_desc_container a{
    color: #7e0d10;
}

.cart_prod_desc_container select{
    width: fit-content;
}

.cart_prod_action_container{
    width: 20%;
    display: flex;
    justify-content: right;
    gap: .5rem;
}

.cart_prod_action_container button{
    width: fit-content;
    height: fit-content;
    background-color: #7e0d10;
    border: none;
}

/* TOTAL DE LA COMPRA */
.total_card{
    border: 1px solid #d5d5d5;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15%;
}

.total_card p{
    font-size: 1.2rem;
}

.total_card span{
    font-weight: 500;
}

.total_card button{
    background-color: #7e0d10;
    border: none;
}

.total_card button:hover{
    background-color: #7e0d11cd;
}

/* seguir pidiendo */
.seguir_pidiendo_container{
    margin: 15px 0;
    text-align: center;
}

.seguir_pidiendo_container a{
    color: #7e0d10;
    text-decoration: none;
}

.seguir_pidiendo_container a:hover{
    text-decoration: underline;
}

@media(max-width: 1500px) {
    .list_car_items{
        flex-direction: column;
    }

    .list_cart_prod,
    .total_card{
        width: 100%;
    }
}

@media(max-width: 991px) {
    .cart_prod_container{
        flex-direction: column;
    }

    .cart_prod_desc_container,
    .cart_prod_action_container,
    .total_card{
        width: 100%;
    }

    .cart_prod_image_container{
        width: fit-content;
        margin: 0 auto;
    }

    .cart_prod_action_container{
        justify-content: center;
    }

    .cart_prod_action_container button{
        padding: 15px 20px;
    }
}

@media(max-width: 767px) {
    .empty_message p, .empty_message p a{
        font-size: 1.1rem;
    }
}

@media(max-width: 479px) {}