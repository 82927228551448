.facilitator_container{
    margin: 0.1rem auto;
    position: relative;
    height: auto;
}

.facilitator_container .row{
    margin: 0;
    margin-top: .3rem;
}

.facilitator_container .action_btn_container{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.action_btn_container button{
    width: fit-content;
    border: none;
}


.facilitator_container .breadcrumbs{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
}
.facilitator_container .breadcrumbs svg{
    width: 1rem;
    filter: invert(67%) sepia(2%) saturate(0%) hue-rotate(101deg) brightness(95%)
}

.facilitator_container .breadcrumbs .active{
    filter: invert(10%) sepia(44%) saturate(6427%) hue-rotate(347deg)
}

.facilitator_container .form_error_input{
    color: red;
    display: none;
}

.facilitator_container input.error,
.facilitator_container select.error{
    border: 2px solid red;
}

.facilitator_container .spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    gap: 15px;
    align-items: center;
    color: #7e0d10;
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.facilitator_container .spinner span{
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
}

.facilitator_container .spinner p{
    font-size: 3rem;
    margin: 0;
    animation: shine 3s infinite ease-out;
}

@keyframes shine {
    from {
        transform: scale(1.1);
    }

    50% {
        transform: none;
    }

    to {        
        transform: scale(1.1);
    }
}

.modal_facilitator button{
    background-color: #7e0d10;
}

.captcha button{
    border-color: #cacaca;
    background-color: #ffffff;
}

.captcha button:hover,
.captcha button:focus{
    background-color: #cacaca;
    border-color: #cacaca;
    box-shadow: none;
}

.captcha .input_disabled_captcha{
    padding: .5rem 1rem!important;
    font-size: 1.3rem;

}

.body_facilitator{
    padding: 1rem;
    background-color: #D1FFE3;
}

@media(max-width: 767px) {
    .facilitator_container .row{
        margin: 0;
    }
    .facilitator_container .action_btn_container{
        margin-top: 1rem;
    }
}

