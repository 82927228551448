.password_reset_container_form{
    width: 80%;
    margin: 1rem auto;
}

.password_reset_container_form input:focus{
    box-shadow: none;
    border-color: #a85154;
}

.password_reset_container_form .form_error_input{
    color: red;
    display: none;
}

.password_reset_container_form input.error{
    border: 2px solid red;
}

.password_reset_container_form button{
    width: 100%;
    background-color: #7e0d10;
    border: none;
    margin-top: 1rem;
    padding: 1rem;
}

.password_reset_container_form button:hover,
.password_reset_container_form button:disabled,
.password_reset_container_form button:focus{
    background-color: #a85154;
    box-shadow: none;
}