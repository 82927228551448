.promotions{
    width: 100%;
    margin-top: 50px;
    padding: 0 30px;
    color: #7e0d10;
}

.promotions h1{
    color: #7e0d10!important;
    text-align: center;
}

.promotions p{
    font-size: 1.1rem;
}

.promotions_button{
    color: #595959;
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    padding: 5px 20px;
    margin-bottom: 20px;
    background: #ffffff;
    border: 2px solid black;
    text-decoration: none;
}

.promotions_button2{
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    padding: 16px 24px;
    background-color: #9DDADB;
    border-radius: 15px;
    display: flex;
    margin: 0 auto;
    margin-top: 30px;
    width: fit-content;
    text-decoration: none;
    color: #000000;
}

.promotions_button:hover, .promotions_button2:hover{
    color: #000000;
    transform: scale(0.98);
    transition: .3s;
}

@media(max-width: 991px) {

    .promotions h1{
        font-size: 1.3rem;
    }

    .promotions p{
        font-size: 1rem;
    }
}

@media(max-width: 767px) {}

@media(max-width: 479px) {
    .promotions{
        padding: 0 10px;
    }
}