.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #27272D;
}

.home-container01 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}

.home-image {
  width: 100px;
  object-fit: cover;
}

.home-image01 {
  width: 100px;
  object-fit: cover;
}

.home-icon {
  width: 24px;
  height: 24px;
}

.home-icon02 {
  width: 24px;
  height: 24px;
}

.home-icon04 {
  width: 24px;
  height: 24px;
}

.home-icon06 {
  width: 24px;
  height: 24px;
}

.home-icon08 {
  width: 24px;
  height: 24px;
}

.home-icon10 {
  width: 24px;
  height: 24px;
}

.home-icon12 {
  width: 24px;
  height: 24px;
}

.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}

.home-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.home-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-text {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text001 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text002 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text003 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text004 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-container03 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}

.home-login {
  margin-right: var(--dl-space-space-twounits);
}

.home-icon-group {
  display: flex;
}

.home-icon18 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.home-icon20 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}

.home-icon22 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-hero {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 659px;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: 4px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-size: cover;
  justify-content: flex-start;
  background-image: url("https://images.unsplash.com/photo-1629241290025-6bb716261f5f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIwfHxnYXNvbGluYSUyMHxlbnwwfHx8fDE2ODQ1MTU2NTZ8MA&ixlib=rb-4.0.3&w=1500");
}

.home-heading {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.home-container04 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.home-container05 {
  width: 833px;
  height: 429px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.home-header {
  color: rgb(255, 255, 255);
  width: 842px;
  font-size: 60px;
  max-width: 900px;
  align-self: flex-end;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  line-height: 50px;
}

.home-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  text-align: center;
  line-height: 36px;
}

.home-buttons {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-learn {
  color: rgb(255, 255, 255);
}

.home-description {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.home-container06 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-gallery {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d" "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}

.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-end;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1514505743617-980a45b2d358?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEwM3x8aG9nYXJ8ZW58MHx8fHwxNjg0NTIyODQ0fDA&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}

.home-container08:hover {
  transform: scale(0.98);
}

.home-container09 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.home-text008 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.home-text009 {
  color: var(--dl-color-gray-white);
  font-size: 50px;
  margin-top: var(--dl-space-space-halfunit);
}

.home-text010 {
  color: #ffffff;
}

.home-text011 {
  color: #ffffff;
}

.home-text012 {
  color: #ffffff;
}

.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
}

.home-container10:hover {
  transform: scale(0.98);
}

.home-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-size: cover;
  background-image: url("/public/playground_assets/hero-divider-1400w.png");

}

.home-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-button05 {
  width: 151px;
}

.home-text013 {
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  margin-left: var(--dl-space-space-unit);
}

.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1637168943285-a8f9ea0dc3f5?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDl8fGZpbmFuemFzfGVufDB8fHx8MTY4NDUyMjg5Mnww&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}

.home-container13:hover {
  transform: scale(0.98);
}

.home-container14 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}

.home-text014 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.home-text015 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}

.home-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1497034825429-c343d7c6a68f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fHllbGxvdyUyMGljZWNyZWFtfGVufDB8fHx8MTYyNjQzOTg4OQ&ixlib=rb-1.2.1&h=1000");
  background-position: center;
}

.home-container15:hover {
  transform: scale(0.98);
}

.home-container16 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.home-text022 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.home-text023 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.home-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1561037404-61cd46aa615b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGRvZ3xlbnwwfHx8fDE2ODQ1MjIyMzV8MA&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}

.home-container17:hover {
  transform: scale(0.98);
}

.home-container18 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.home-text025 {
  color: var(--dl-color-gray-white);
  font-size: 2rem;
  font-weight: 600;
}

.home-text026 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}

.home-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1591101761702-0c4927df1d52?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fHBhc3RlbCUyMHBpbmslMjBjb3R0b258ZW58MHx8fHwxNjI2NDQwMDUw&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}

.home-container19:hover {
  transform: scale(0.98);
}

.home-container20 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.home-text027 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}

.home-text028 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.home-project {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}

.home-understand {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-content {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.home-caption01 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.home-heading01 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header01 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

.home-header02 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.home-view06 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  text-align: left;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
}

.home-image02 {
  width: 25px;
  object-fit: cover;
}

.home-image03 {
  width: 480px;
  object-fit: cover;
}

.home-mining {
  gap: 130px;
  width: 1179px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 15px;
  justify-content: center;
  background-color: rgba(188, 148, 89, 0.15);
}

.home-container21 {
  width: 1075px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-step {
  width: 430px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-container22 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}

.home-line {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container23 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-icon24 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}

.home-line1 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container24 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.home-text039 {
  color: #ffffff;
  width: 125px;
  height: 54px;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}

.home-text040 {
  color: #ffffff;
  height: auto;
  font-size: 1rem;
  text-align: center;
}

.home-step1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}

.home-line2 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container26 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-icon26 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}

.home-line3 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container27 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.home-text043 {
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}

.home-text044 {
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
}

.home-step2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-container28 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}

.home-line4 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container29 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-icon28 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}

.home-line5 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container30 {
  width: 267px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.home-text047 {
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}

.home-text048 {
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
}

.home-step3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-container31 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}

.home-line6 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container32 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-icon31 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}

.home-line7 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container33 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}

.home-text051 {
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}

.home-text052 {
  color: #ffffff;
  font-size: 0.75rem;
  text-align: center;
}

.home-view07 {
  margin-top: -25px;
}

.home-collection {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 28px;
}

.home-content01 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-caption02 {
  color: #FFCF77;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.home-heading02 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header03 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

.home-header04 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.home-view08 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 45px;
  display: flex;
  text-align: left;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 4px;
}

.home-image04 {
  width: 25px;
  object-fit: cover;
}

.home-main {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(4, 1fr);
}

.home-card {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}

.home-image05 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #FDEA6B;
}

.home-image06 {
  width: 285px;
  height: 320px;
  object-fit: cover;
}

.home-content02 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-caption03 {
  font-style: normal;
  font-weight: 600;
}

.home-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-button06 {
  width: 236px;
  height: 42px;
}

.home-card01 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}

.home-image07 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #6bf5fd;
}

.home-image08 {
  width: 285px;
  height: 320px;
  object-fit: cover;
}

.home-content03 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-caption04 {
  font-style: normal;
  font-weight: 600;
}

.home-title1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-button07 {
  width: 236px;
  height: 42px;
}

.home-card02 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}

.home-image09 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #b5ab6d;
}

.home-image10 {
  width: 285px;
  height: 320px;
  object-fit: cover;
}

.home-content04 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-caption05 {
  font-style: normal;
  font-weight: 600;
}

.home-title2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-button08 {
  width: 236px;
  height: 42px;
}

.home-card03 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}

.home-image11 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #5169c9;
}

.home-image12 {
  width: 285px;
  height: 320px;
  object-fit: cover;
}

.home-content05 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-caption06 {
  font-style: normal;
  font-weight: 600;
}

.home-title3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-button09 {
  width: 236px;
  height: 42px;
}

.home-card04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}

.home-image13 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #d41925;
}

.home-image14 {
  width: 285px;
  height: 320px;
  object-fit: cover;
}

.home-content06 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-caption07 {
  font-style: normal;
  font-weight: 600;
}

.home-title4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-button10 {
  width: 236px;
  height: 42px;
}

.home-card05 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}

.home-image15 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #FDEA6B;
}

.home-image16 {
  width: 285px;
  height: 320px;
  object-fit: cover;
}

.home-content07 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-caption08 {
  font-style: normal;
  font-weight: 600;
}

.home-title5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-button11 {
  width: 236px;
  height: 42px;
}

.home-card06 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}

.home-image17 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #aaaaa7;
}

.home-image18 {
  width: 285px;
  height: 320px;
  object-fit: cover;
}

.home-content08 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-caption09 {
  font-style: normal;
  font-weight: 600;
}

.home-title6 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-button12 {
  width: 236px;
  height: 42px;
}

.home-card07 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}

.home-image19 {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}

.home-image20 {
  width: 285px;
  height: 320px;
  object-fit: cover;
}

.home-content09 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: flex-start;
  flex-direction: column;
}

.home-caption10 {
  font-style: normal;
  font-weight: 600;
}

.home-title7 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-button13 {
  width: 236px;
  height: 42px;
}

.home-view09 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 45px;
  display: flex;
  text-align: left;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 4px;
}

.home-image21 {
  width: 25px;
  object-fit: cover;
}

.home-cards {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}

.home-row {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-card08 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #000000;
}

.home-avatar {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: #FDEA6B;
}

.home-avatar1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-main1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-content10 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header05 {
  color: rgb(255, 255, 255);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}

.home-description01 {
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}

.home-learn1 {
  gap: var(--dl-space-space-unit);
  color: white;
  display: flex;
  padding: 0px;
  box-shadow: none;
  align-items: center;
  padding-top: 0px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  background-color: transparent;
}

.home-text085 {
  font-style: normal;
  font-weight: 500;
}

.home-image22 {
  width: 16px;
  object-fit: cover;
}

.home-card09 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #FFCF77;
}

.home-avatar2 {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #EC7495;
}

.home-avatar3 {
  width: 28px;
  object-fit: cover;
}

.home-main2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-content11 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header06 {
  color: rgb(0, 0, 0);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}

.home-description02 {
  color: #000000;
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}

.home-learn2 {
  gap: var(--dl-space-space-unit);
  color: #000000;
  display: flex;
  padding: 0px;
  box-shadow: none;
  align-items: center;
  padding-top: 0px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  background-color: transparent;
}

.home-text086 {
  font-style: normal;
  font-weight: 500;
}

.home-image23 {
  width: 16px;
  object-fit: cover;
}

.home-card10 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #8FA7DF;
}

.home-avatar4 {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}

.home-avatar5 {
  width: 22px;
  object-fit: cover;
}

.home-row1 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.home-main3 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}

.home-content12 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header07 {
  color: rgb(0, 0, 0);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}

.home-description03 {
  color: #000000;
  width: 100%;
  font-size: 18px;
  max-width: 400px;
  line-height: 27px;
}

.home-learn3 {
  gap: var(--dl-space-space-unit);
  color: #000000;
  display: flex;
  padding: 0px;
  box-shadow: none;
  align-items: center;
  padding-top: 0px;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  background-color: transparent;
}

.home-text087 {
  font-style: normal;
  font-weight: 500;
}

.home-image24 {
  width: 16px;
  object-fit: cover;
}

.home-image25 {
  width: 100%;
  object-fit: cover;
}

.home-container34 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-end;
  padding-top: 91px;
  flex-direction: column;
}

.home-description04 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}

.home-content13 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-paragraph {
  color: #ffffff;
  font-size: 40px;
  line-height: 60px;
}

.home-paragraph1 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  line-height: 60px;
}

.home-links {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.home-arrow {
  width: 25px;
  object-fit: cover;
}

.home-link01 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  border-top-width: 0px;
}

.home-arrow1 {
  width: 25px;
  object-fit: cover;
}

.home-roadmap {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}

.home-heading03 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: center;
  flex-direction: column;
}

.home-header08 {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  font-weight: 500;
  line-height: 108px;
}

.home-header09 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  text-align: center;
  line-height: 36px;
}

.home-list {
  gap: 106px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  border-left-width: 1px;
}

.home-step4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-caption11 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.home-heading04 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header10 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}

.home-header11 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.home-button14 {
  margin-top: var(--dl-space-space-oneandhalfunits);
}

.home-step5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-caption12 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.home-heading05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header12 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}

.home-header13 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.home-step6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-caption13 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.home-heading06 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header14 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}

.home-header15 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.home-step7 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-caption14 {
  color: rgb(255, 207, 119);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.home-heading07 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header16 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
}

.home-header17 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.home-benefits {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-item {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-image26 {
  width: 16px;
  object-fit: cover;
}

.home-header18 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.home-item1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-image27 {
  width: 16px;
  object-fit: cover;
}

.home-header19 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.home-item2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-image28 {
  width: 20px;
  object-fit: cover;
}

.home-header20 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.home-join-us {
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}

.home-content14 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: #FFCF77;
}

.home-main4 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-heading08 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-header21 {
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}

.home-caption15 {
  font-size: 24px;
  max-width: 800px;
  line-height: 36px;
}

.home-view10 {
  background-color: #9DDADB;
}

.home-image29 {
  width: 100%;
  object-fit: cover;
}

.home-faq {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}

.home-header22 {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}

.home-accordion {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
}

.home-element {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
  border-top-width: 1px;
}

.home-content15 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header23 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  padding-right: var(--dl-space-space-oneandhalfunits);
}

.home-description05 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}

.home-icon-container {
  padding-top: var(--dl-space-space-halfunit);
}

.home-icon33 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}

.home-icon35 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}

.home-element1 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-content16 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header24 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}

.home-description06 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}

.home-icon-container1 {
  padding-top: var(--dl-space-space-halfunit);
}

.home-icon37 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}

.home-icon39 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}

.home-element2 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-content17 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header25 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}

.home-description07 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}

.home-icon-container2 {
  padding-top: var(--dl-space-space-halfunit);
}

.home-icon41 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}

.home-icon43 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}

.home-element3 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-content18 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header26 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}

.home-description08 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}

.home-icon-container3 {
  padding-top: var(--dl-space-space-halfunit);
}

.home-icon45 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}

.home-icon47 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}

.home-element4 {
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: rgba(255, 255, 255, 0.3);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-content19 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header27 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  padding-right: var(--dl-space-space-oneandhalfunits);
}

.home-description09 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  max-width: 800px;
  line-height: 27px;
}

.home-icon-container4 {
  padding-top: var(--dl-space-space-halfunit);
}

.home-icon49 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
  display: none;
}

.home-icon51 {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}

.home-get-yours {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 555px;
  display: flex;
  max-width: 1440px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}

.home-row2 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  flex-direction: row;
}

.home-column {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-card11 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
  background-color: #FFCF77;
}

.home-image30 {
  width: 240px;
  object-fit: cover;
}

.home-column1 {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

.home-card12 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}

.home-image31 {
  width: 240px;
  object-fit: cover;
}

.home-column2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-card13 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: #8FA7DF;
}

.home-content20 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 460px;
  align-items: flex-start;
  flex-direction: column;
}

.home-header28 {
  color: rgb(0, 0, 0);
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}

.home-description10 {
  color: rgb(0, 0, 0);
  width: 100%;
  font-size: 18px;
  max-width: 500px;
  line-height: 27px;
}

.home-button15 {
  margin-top: var(--dl-space-space-oneandhalfunits);
}

.home-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-main5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.home-branding {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}

.home-heading09 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

/* .home-logo2 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
  } */
.home-caption16 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.home-twitter1 {
  text-decoration: none;
}

.home-image32 {
  width: 100px;
  object-fit: cover;
}

.home-discord1 {
  text-decoration: none;
}

.home-image33 {
  width: 100px;
  object-fit: cover;
}

.home-links1 {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-list1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-heading10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.home-items {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-link02 {
  color: rgb(255, 255, 255);
}

.home-link03 {
  color: rgb(255, 255, 255);
}

.home-link04 {
  color: rgb(255, 255, 255);
}

.home-link05 {
  color: rgb(255, 255, 255);
}

.home-list2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-heading11 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.home-items1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-link06 {
  color: rgb(255, 255, 255);
}

.home-link07 {
  color: rgb(255, 255, 255);
}

.home-link08 {
  color: rgb(255, 255, 255);
}

.home-link09 {
  color: rgb(255, 255, 255);
}

.home-link10 {
  color: rgb(255, 255, 255);
}

.home-twitter2 {
  text-decoration: none;
}

.home-image34 {
  width: 100px;
  object-fit: cover;
}

.home-discord2 {
  text-decoration: none;
}

.home-image35 {
  width: 100px;
  object-fit: cover;
}

.home-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.home-pricing {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5ff;
}

.home-container35 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  justify-content: center;
}

.home-container36 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}

.home-text101 {
  color: var(--dl-color-gray-500);
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-container37 {
  flex: 2;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.home-container38 {
  flex: 1;
  height: 450px;
  display: flex;
  max-width: 450px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-white);
}

.home-container38:hover {
  transform: scale(1.02);
}

.home-text105 {
  font-size: 1.5rem;
  font-weight: 600;
}

.home-text106 {
  margin: var(--dl-space-space-twounits);
  font-size: 4rem;
  font-weight: 200;
}

.home-text107 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text108 {
  font-weight: 700;
}

.home-text110 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text111 {
  font-weight: 700;
}

.home-text113 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text114 {
  font-weight: 700;
}

.home-text116 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text117 {
  font-weight: 700;
}

.home-button16 {
  color: var(--dl-color-gray-white);
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}

.home-container39 {
  flex: 1;
  color: var(--dl-color-gray-white);
  height: 450px;
  display: flex;
  max-width: 450px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-black);
}

.home-container39:hover {
  transform: scale(1.02);
}

.home-text118 {
  font-size: 1.5rem;
  font-weight: 600;
}

.home-text119 {
  margin: var(--dl-space-space-twounits);
  font-size: 4rem;
  font-weight: 200;
}

.home-text120 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text121 {
  font-weight: 700;
}

.home-text123 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text124 {
  font-weight: 700;
}

.home-text126 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text127 {
  font-weight: 700;
}

.home-text129 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text130 {
  font-weight: 700;
}

.home-button17 {
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
}

@media(max-width: 991px) {
  .home-gallery {
    grid-gap: var(--dl-space-space-unit);
  }

  .home-text008 {
    text-align: center;
  }

  .home-text009 {
    text-align: center;
  }

  .home-text014 {
    text-align: center;
  }

  .home-text015 {
    text-align: center;
  }

  .home-text022 {
    text-align: center;
  }

  .home-text023 {
    text-align: center;
  }

  .home-text025 {
    text-align: center;
  }

  .home-text026 {
    text-align: center;
  }

  .home-text027 {
    text-align: center;
  }

  .home-text028 {
    text-align: center;
  }

  .home-project {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }

  .home-understand {
    align-items: center;
    flex-direction: column;
  }

  .home-mining {
    flex-direction: column-reverse;
  }

  .home-main {
    grid-template-columns: repeat(2, 1fr);
  }

  .home-row {
    flex-direction: column;
  }

  .home-card08 {
    width: 100%;
  }

  .home-card09 {
    width: 100%;
  }

  .home-row1 {
    flex-direction: column;
  }

  .home-heading08 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-caption15 {
    text-align: center;
  }

  .home-get-yours {
    height: auto;
    align-items: center;
    flex-direction: column;
  }

  .home-row2 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    height: 555px;
    align-items: center;
    justify-content: center;
  }

  .home-column {
    flex: 1;
    align-items: flex-start;
  }

  .home-card11 {
    width: 100%;
    aspect-ratio: 0.85;
    justify-content: center;
  }

  .home-column1 {
    flex: 1;
    align-items: flex-end;
  }

  .home-card12 {
    width: 100%;
    aspect-ratio: 0.85;
  }

  .home-column2 {
    width: 100%;
  }

  .home-container35 {
    align-items: center;
    flex-direction: column;
  }

  .home-container36 {
    align-items: center;
  }

  .home-text101 {
    text-align: center;
  }

  .home-container37 {
    width: 100%;
  }
}

@media(max-width: 767px) {

  .home-hero {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-heading {
    gap: var(--dl-space-space-unit);
  }

  .home-header {
    font-size: 36px;
    max-width: 70%;
    line-height: 43px;
  }

  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }

  .home-description {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }

  .home-gallery {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }

  .home-project {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }

  .home-understand {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading01 {
    gap: var(--dl-space-space-unit);
  }

  .home-header01 {
    font-size: 32px;
    letter-spacing: 0;
  }

  .home-header02 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-text030 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-view06 {
    margin-top: var(--dl-space-space-unit);
  }

  .home-image03 {
    width: 100%;
  }

  .home-mining {
    gap: var(--dl-space-space-fourunits);
  }

  .home-container21 {
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }

  .home-step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }

  .home-container22 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }

  .home-line1 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-container24 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }

  .home-text039 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-text040 {
    text-align: left;
  }

  .home-step1 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }

  .home-container25 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }

  .home-line2 {
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-line3 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-container27 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }

  .home-text043 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-text044 {
    text-align: left;
  }

  .home-step2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }

  .home-container28 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }

  .home-line4 {
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-line5 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-container30 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }

  .home-text047 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-text048 {
    text-align: left;
  }

  .home-step3 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }

  .home-container31 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }

  .home-line6 {
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-line7 {
    border-color: transparent;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }

  .home-container33 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }

  .home-text051 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-text052 {
    text-align: left;
  }

  .home-collection {
    gap: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-content01 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading02 {
    gap: var(--dl-space-space-unit);
  }

  .home-header03 {
    font-size: 32px;
    letter-spacing: 0;
  }

  .home-header04 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-text055 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-view08 {
    margin-top: var(--dl-space-space-unit);
  }

  .home-image05 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-image07 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-image09 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-image11 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-image13 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-image15 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-image17 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-image19 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-view09 {
    margin-top: var(--dl-space-space-unit);
  }

  .home-cards {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-card08 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-main1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-header05 {
    font-size: 24px;
  }

  .home-description01 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-card09 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-main2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-header06 {
    font-size: 24px;
  }

  .home-description02 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-card10 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-row1 {
    gap: var(--dl-space-space-twounits);
  }

  .home-main3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-header07 {
    font-size: 24px;
  }

  .home-description03 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-description04 {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-content13 {
    gap: var(--dl-space-space-unit);
  }

  .home-paragraph {
    font-size: 20px;
    line-height: 30px;
  }

  .home-paragraph1 {
    font-size: 20px;
    line-height: 30px;
  }

  .home-roadmap {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-heading03 {
    gap: var(--dl-space-space-unit);
  }

  .home-header08 {
    font-size: 36px;
    line-height: 43px;
  }

  .home-header09 {
    width: 80%;
    font-size: 16px;
    line-height: 24px;
  }

  .home-list {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }

  .home-heading04 {
    gap: var(--dl-space-space-unit);
  }

  .home-header10 {
    font-size: 32px;
  }

  .home-header11 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-button14 {
    margin-top: 0px;
  }

  .home-heading05 {
    gap: var(--dl-space-space-unit);
  }

  .home-header12 {
    font-size: 32px;
  }

  .home-header13 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-heading06 {
    gap: var(--dl-space-space-unit);
  }

  .home-header14 {
    font-size: 32px;
  }

  .home-header15 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-heading07 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-header16 {
    font-size: 32px;
  }

  .home-header17 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header18 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header19 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-image28 {
    width: 17px;
  }

  .home-header20 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-join-us {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-content14 {
    padding-top: var(--dl-space-space-threeunits);
  }

  .home-main4 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading08 {
    gap: var(--dl-space-space-unit);
  }

  .home-header21 {
    font-size: 36px;
    line-height: 43px;
  }

  .home-caption15 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-image29 {
    width: 150%;
  }

  .home-faq {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-header22 {
    font-size: 36px;
    line-height: 43px;
  }

  .home-element {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-header23 {
    font-size: 16px;
  }

  .home-description05 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-element1 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-header24 {
    font-size: 16px;
  }

  .home-description06 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-element2 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-header25 {
    font-size: 16px;
  }

  .home-description07 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-element3 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-header26 {
    font-size: 16px;
  }

  .home-description08 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-element4 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-header27 {
    font-size: 16px;
  }

  .home-description09 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-get-yours {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-row2 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: 555px;
  }

  .home-card11 {
    padding: var(--dl-space-space-unit);
  }

  .home-image30 {
    width: 100%;
  }

  .home-card12 {
    padding: var(--dl-space-space-unit);
  }

  .home-image31 {
    width: 100%;
  }

  .home-card13 {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-content20 {
    align-items: center;
  }

  .home-header28 {
    font-size: 32px;
    text-align: center;
  }

  .home-description10 {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
  }

  .home-button15 {
    margin-top: 0px;
  }

  .home-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-main5 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }

  .home-heading09 {
    gap: var(--dl-space-space-unit);
  }

  /* .home-logo2 {
      font-size: 20px;
      line-height: 24px;
    } */
  .home-caption16 {
    width: 80%;
    font-size: 12px;
    line-height: 18px;
  }

  .home-links1 {
    gap: var(--dl-space-space-fourunits);
    width: 100%;
    justify-content: flex-start;
  }

  .home-list1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-list2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-container35 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-container37 {
    flex-direction: column;
  }

  .home-container38 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-container39 {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .home-mobile-menu {
    padding: 16px;
  }

  .home-gallery {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }

  .home-container21 {
    padding-left: 0px;
  }

  .home-container24 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-text039 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }

  .home-container27 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-text043 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }

  .home-container30 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-text047 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }

  .home-container33 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-text051 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }

  .home-main {
    grid-template-columns: repeat(1, 1fr);
  }

  .home-image29 {
    width: 200%;
  }

  .home-header23 {
    font-size: 16px;
  }

  .home-description05 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header24 {
    font-size: 16px;
  }

  .home-description06 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header25 {
    font-size: 16px;
  }

  .home-description07 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header26 {
    font-size: 16px;
  }

  .home-description08 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header27 {
    font-size: 16px;
  }

  .home-description09 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-row2 {
    height: 350px;
  }

  .home-container35 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}