.prod_portada{
    padding: 2.5rem 0 1.5rem;
    background-color: #ebebeb;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #D1FFE3;
    color: #7e0d10 !important;
}



.prod_portada h3{
    text-align: center;
    font-size: 2.1rem;
}

.prod_container{
    display: flex;
    width: 100%;
    padding: 0 30px;
    margin: 50px auto 0 auto;
}

.filters{
    width: 20%;
}

.filter_box{
    border-top: .5px solid #e6e6e6;
    padding: 20px 0;
}

.filter_title{
    font-size: 1.6rem;
}

.filter_container{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.filter_container a{
    text-decoration: none;
    color: #000000;
    font-size: 1rem;
}

.filter_container a:hover{
    text-decoration: underline;
    color: #9f9f9f;
}

.prod_list{
    padding: 0 30px;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
}

.prod_item{
    width: 19rem;
    margin: 10px 0;
    box-shadow: 0 0 9px -7px #000000;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 10px;
    border: 1px solid #e6e6e6;
    text-decoration: none;
    color: #000000!important;
    font-size: unset;
}

.prod_item:hover{
    transform: scale(.98);
    transition: .3s;
}

.prod_item .prod_img,
.prod_item .prod_img_hover{
    width: fit-content;
    min-height: 15rem;
    max-height: 15rem;
    display: block;
    margin: 0 auto;
}

.prod_item .prod_img_hover,
.prod_item:hover .prod_img{
    display: none;
}

.prod_item:hover .prod_img_hover{
    display: block;
}

.prod_item .prod_text_box{
    padding: 10px 20px;
}

.prod_item .prod_text_box a{
    text-decoration: none;
    color: #000000;
}

.prod_text_box .prod_title{
    font-size: 1.1rem;
    text-decoration: none;
    color: #7e0d10;
    font-weight: 500;
    margin: 0;
}

.prod_item:hover .prod_title{
    text-decoration: underline;
}

.prod_text_box .prices{
    display: flex;
    gap: 15px;
    line-height: 1;
    padding: 10px 0;
    justify-content: center;
}

.prices .offer_price{
    color: #7e0d10;
    font-weight: 500;
    font-size: 2.5rem;
    margin: 0;
}

.prices .normal_price{
    color: #9f9f9f;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: line-through;
    margin: 0;
    display: flex;
    align-self: end;
}

.prod_text_box .see_more{
    padding: 5px 15px;
    background-color: #7e0d10;
    width: fit-content;
    display: flex;
    margin: 15px auto 0 auto;
    color: #ffffff!important;
    text-decoration: none;
    border-radius: 6px;
}

.empty_products_message{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.quantity_container{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.quantity_container .num_container{
    display: flex;
}

.num_container button{
    border: none;
    padding: .3rem .5rem;
    background-color: #7e0d10;
    color: #ffffff;
}

.num_container .num_btn_l{
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
}

.num_container .num_btn_r{
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
}

.num_container span{
    padding: 0 .5rem;
    align-items: center;
    display: flex;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.quantity_container .button_set_cart{
    border: none;
    padding: .3rem .5rem;
    background-color: #7e0d10;
    color: #ffffff;
    font-size: .9rem;
    border-radius: .4rem;
}

.quantity_error_container{
    color: #7e0d10;
    line-height: 1;
    margin-top: 1rem;
    font-weight: bold;
}

@media(max-width: 1500px) {}

@media(max-width: 1000px) {
    .prod_container{
        flex-direction: column;
    }

    .prod_list,
    .filters,
    .prod_container{
        width: 100%;
    }
}

@media(max-width: 767px) {
    .prod_item{
        width: 100%;
        max-width: none;
    }
}

@media(max-width: 479px) {
    .prod_list{
        padding: 0;
    }
    
    .prices .offer_price{
        font-size: 1rem;
    }

    .quantity_container{
        flex-direction: column;
        gap: 5px;
    }

    .quantity_container .num_container{
        justify-content: space-between;
    }

    .num_container span{
        width: 100%;
        justify-content: center;
    }
    /* 
    
    .prices .normal_price,
    .prod_text_box .prod_title,
    .prod_item .prod_desc,
    .prod_item .prod_prov{
        font-size: .8rem;
    }

    .prod_item .prod_desc,
    .prod_item .prod_prov{
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }

    .prod_item .rating span{
        font-size: .8rem;
    } */
}