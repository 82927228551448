.cards_section_container {
    width: 100%;
    margin-top: 50px;
    padding: 0 30px;
    color: #b2afbb;
}

.cards_section_container h1 {
    color: #fad6a5 !important;
    text-align: center;
    text-transform: uppercase;
}

.cards_section_container p {
    line-height: 1;
    font-size: 1.1rem;
}

.cards_container {
    display: flex;
    gap: 1rem;
    margin-bottom: 10px;
}

.card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 50%;
    gap: .5rem;
}

.card .card_img_container{
    width: fit-content;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
}

.card .card_text_container{
    display: flex;
    flex-direction: column;
}

.card .card_text_container img{
    width: 25rem;
    margin: 0 auto;
}

.card_aliados .card_img_container{
    background-color: #fdea6b;
}

.card_aliados .card_img_container img{
    width: 4rem;
}

.card_text_container h1{
    font-size: 1.3rem;
}

.card_text_container p{
    line-height: normal;
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.card_text_container a{
    box-shadow: 5px 6px 0px 0px #ffffff;
    font-weight: 500;
    border: 1px solid #ffffff;
    padding: 13px 20px;
    background-color: transparent;
    border-radius: 15px;
    width: fit-content;
    text-decoration: none;
    color: #ffffff!important;
    font-size: inherit;
}

.card_text_container a:hover,
.card_text_container button:hover{
    transform: scale(1.05);
    transition: .3s;
}

.card_aliados,
.card_aliados h1 {
    background-color: #000000;
    color: #ffffff !important;
    text-align: left;
}

.card_promo .card_img_container{
    padding: 18px;
    background-color: #EC7495;
}

.card_promo .card_img_container img{
    width: 2rem;
}

.card_promo,
.card_promo h1 {
    background-color: #FFCF77;
    color: #000000 !important;
    text-align: left;
}

.card_promo a,
.card_union button,
.card_work button{
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    padding: 13px 20px;
    background-color: transparent;
    border-radius: 15px;
    width: fit-content;
}

.card_promo a{
    color: #000000!important;
    border-color: #000000;
    cursor: pointer;
}

.card_union, .card_work{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 15px 0;
}

.card_union .first_div{
    width: 50%;
    flex-direction: column;
    margin: auto 0;
}

.card_union,
.card_union h1 {
    background-color: #8FA7DF;
    color: #000000 !important;
    text-align: left;
}

.card_union .card_img_container{
    padding: 10px 19px;
    background-color: #000000;
}

.card_union .card_img_container img{
    width: 1.5rem;
}

.card_union .card_img{
    width: 50%;
}

.card_work,
.card_work h1{
    background-color: #7e0d108f;
    color: #ffffff!important;

}

.card_work .card_text_container{
    width: 50%;
}

.card_work h1{
    background-color: transparent!important;
    font-size: 2rem;
}

.card_work button{
    background-color: #FFCF77;
}

.card_work .card_img{
    width: 33%;
    margin: 0 auto;
}

.card_work a{
    color: #000000!important;
    border: none;
    border-width: 1px;
    background-color: rgb(255, 207, 119);
    box-shadow: 5px 6px 0px 0px #000000;
}

.card_work ul{
    font-size: 1.1rem;
}

.card_create_account{
    width: 100%;
    background-color: #FFCF77;
    padding: 30px 0;
    text-align: center;
    padding-bottom: 15rem;
    background-image: url('../images/registrar.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

.card_create_account p{
    color: #000000;
    font-size: 2rem;
    line-height: 1.5;
}

.card_create_account a{
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    padding: 13px 20px;
    background-color: #9DDADB;
    width: fit-content;
    color: #000000!important;
    text-decoration: none;
}

.card_create_account a:hover{
    background-color: #9ddadbd2;
}

@media(max-width: 991px) {
    .card_union, .card_work, .cards_container{
        flex-direction: column;
    }
    
    .card, .card_union .first_div,
    .card_work .card_text_container{
        width: 100%;
    }
    .card_union .card_img{
        margin: 0 auto;
    }

    .cards_section_container h1{
        font-size: 1.3rem;
    }

    .cards_section_container p{
        font-size: 1rem;
    }

    .card{
        margin: 0;
        margin-bottom: 1rem;
    }

    .cards_container{
        gap: 0;
        margin-bottom: 0;
    }
}

@media(max-width: 767px) {}

@media(max-width: 479px) {}