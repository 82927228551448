.nosotros_container {
    width: 100%;
    display: flex;
    margin-top: 140px;
    padding: 0 30px;
}

.texto_container {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.style_title {
    font-size: 3rem;
    color: #87B338;
    line-height: 1;
}

.texto_container p {
    font-size: 1.2rem;
}

.texto_container iframe{
    height: 100%;
}

.img_style{
    transform: scaleX(-1);
}

.imagen_container {
    width: 50%;
}

.imagen_container img {
    width: 60%;
    height: auto;
    margin: 0 auto;
    display: block;
}

.video_container {
    position: relative;
    width: 103%;
    height: 93%;
}

  .video_container video {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%;
    height: 100%; 
    object-fit: cover;
  }

  .video_container {
    border-radius: 20px;
    box-shadow: 0 0 10px #87B338;
  }

@media(max-width: 991px) {
    .nosotros_container {
        flex-direction: column;
        margin-top:150px;
        padding: 0 15px 50px 15px;
    }
    
    .video_container {
        position: relative;
        width: 100%;
        height: 130%;
    }

    .imagen_container img {
        display: none;
    }

    .style_title,
    .imagen_container {
        width: 100%;
    }

    .style_title h1 {
        width: 100%;
        text-align: center;
    }

    .style_title iframe{
        height: 25rem;
    }
}

@media(max-width: 767px) {
    .style_title h1 { 
        font-size: 2rem;
    }

    .video_container {
        position: relative;
        width: 100%;
        height: 130%;
    }

    .imagen_container img {
        display: none;
    }

    .style_title iframe{
        height: 20rem;
    }
}

@media(max-width: 479px) {
    .style_title  iframe{
        height: 15rem;
    }
}
