:root {
  --up: 3;
  --card-margin: 12px;
  --card-shadow: rgba(46, 49, 52, .4);
  --color-white: #fff;
  --color-lightgrey: #ccc;
  --cardWidth: calc(96vw/var(--up));
  --carouselWidth: calc(var(--cardWidth) * var(--num));
  --carouselLeftMargin: calc(2vw - var(--cardWidth));
}

.carouselwrapper {
  overflow: hidden;
  position: relative;
}

.customCarousel {
  max-width: var(--carouselWidth);
  margin: 0 0 0 var(--carouselLeftMargin);
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  /* margin: 0; */
  justify-content: center;
  width: max-content;
  gap: 15px;

  margin: 0 auto;
}

.customCarousel.prev {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  animation-name: prev;
}

.customCarousel.next {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  animation-name: next;
}

.customCarousel .card {
  padding: 50px;
  text-align: center;
  width: calc(var(--cardWidth) - var(--card-margin) * 2);
  box-sizing: border-box;
}

.carousel_button_container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: calc(100% - var(--card-margin) * 2);
  justify-content: space-between;
  padding: var(--card-margin);
  z-index: 100;
}

.carousel_button_container button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--color-white);
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, .25);
  border: 0;
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.carousel_button_container button:hover {
  background: #666;
}

.carousel_button_container button:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 1);
}

.card {
  box-shadow: 0px 1px 2px 0px var(--card-shadow);
  border-radius: 4px;
  margin: var(--card-margin);
  background: var(--color-white);
}

.card .material-icons {
  font-size: 4.8rem;
}

.card p {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0;
}

@keyframes next {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(calc(0px + var(--cardWidth)));
  }
}

@keyframes prev {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(calc(0px - var(--cardWidth)));
  }
}


@media(max-width: 991px) {
  :root {
    /*basically mobile*/
    --up: 1;
  }
}

@media(max-width: 767px) {
  .promotions .prod_item{
    width: 20rem;
  }
}

@media(max-width: 479px) {
  .customCarousel{
    margin: 0 2vw;
  }
}