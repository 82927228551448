.steps{
    width: 100%;
    margin-top: 50px;
    padding: 0 30px;
}

.steps_container{
    display: flex;
    gap: 5%;
}

.step{
    width: 20%;
    text-align: center;
}

.step .number{
    font-size: 4rem;
    font-weight: 500;
    color: rgb(217, 217, 217);
    margin: 0;
}

.step .title{
    font-size: 1.3rem;
    font-weight: 500;
}

.step .body{
    font-size: 1rem;
    font-family: Inter;
}

.button_steps {
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    padding: 16px 24px;
    background-color: #ffcf77;
    border-radius: 15px;
    display: flex;
    margin: 0 auto;
    width: fit-content;
    text-decoration: none;
    color: #000000;
}

.button_steps:hover{
    background-color: #ffcf77da;
    color: #000000;
}

@media(max-width: 991px) {
    .steps_container{
        flex-direction: column;
        gap: 5%;
    }
    .step{
        width: 100%;
    }
}

@media(max-width: 767px) {}

@media(max-width: 479px) {}
