header{
  box-shadow: 0 0 10px #87B338;
  margin: 0 auto;
  padding: 7px 2em;
  width: 100%;
  z-index: 1;
  border-radius: 0px 0px 17px 17px;
}

li {
  list-style: none;
}

/*puntero cuando se pone encima*/
li:hover {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}

a:hover {
  color: #87B338;
  cursor: pointer;
}

header {
  position: relative;
}

/* Estilo para el color naranja */
.navbar i.fa-bars,
.navbar i.fa-xmark {
  color: #87B338;
}

/* Estilos generales para barra nav */
.navbar {
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  background-color: #FEFCFB;
}

.navbar a  {
  width: 20%;
  height: auto;
  padding: 0;
  margin: 0;
}

img{
  width: 120px;
  height: 50px;
}

.logo{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  width: 20%;
  height: auto;
  padding: 0;
  margin: 0;
}

.navbar .links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 0;
}

.links li {
  color: #000;
}

.navbar .toggle_btn {
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}


/* Buttons */

.navbar .action_btn {
  display: inline;
  margin: 0;
}

.navbar .action_btn > * {
  margin-right: 10px; /* Ajusta el valor del margen según tus necesidades */
}
.navbar .action_btn > *:last-child {
  margin-right: 0; /* Para evitar un margen adicional en el último elemento */
}

.slogan{
  background: #87B338;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.action_btn_menu {
  background-color: #87B338;
  color: #171721;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  transition: scale 0.2 ease;
}

.action_btn_menu:hover {
  scale: 1.05;
  color: #fff;
}

.action_btn_menu:active {
  scale: 0.95;
}

.action_btn_index_fill {
  background-color: #87B338;
  color: #171721;
  padding: 0.8rem 1rem;
  border: none;
  outline: none;
  width: 150px;
  border-radius: 10px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  transition: scale 0.5 ease;
}

.action_btn_index_out {
  background-color: #171721;
  color: #fff;
  padding: 0.8rem 1rem;
  border-color: #87B338;
  outline: none;
  width: 150px;
  border-radius: 10px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  transition: scale 0.5 ease;
  margin-right: 20px;
}

.action_btn_index_out:hover {
  scale: 1.05;
  color: #fff;
}

.action_btn_index_fill:hover {
  scale: 1.05;
  color: #fff;
}

.action_btn_index_fill:active {
  scale: 0.95;
}

.action_btn_index_out:active {
  scale: 0.95;
}

/*  Dropdown menu */
.dropdown_menu {
  display: none;
  position: absolute;
  right: 2rem;
  top: 60px;
  height: 0;
  width: 300px;
  background: rgba(255, 255, 255, 0.1019607843);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu.open {
  height: auto;
}

.dropdown_menu li {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown_menu .action_btn_menu {
  width: 100%;
  display: flex;
  justify-content: center;
}


/** MOBILE */
@media (max-width: 930px) {
  .navbar .links,
  .navbar .action_btn {
    display: none;
  }
  .navbar .toggle_btn {
    display: block;
  }
  .dropdown_menu {
    display: block;
  }
}
/*# sourceMappingURL=main.css.map */