.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background: linear-gradient(to right, #533566, #9146F3);
    text-align: center;
    white-space: nowrap;
    background-color: #000000;
    transition: var(--bs-progress-bar-transition);
}

.barStyle{
    margin-bottom: 20px;
}