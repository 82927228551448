.shipping_container h2{
    text-align: center;
    color: #7e0d10;
    text-transform: uppercase;
}

.shipping_container .nav{
    margin: 0!important;
}

.shipping_container .nav-link{
    color: #7e0d10!important;
    font-size: 1.5rem;
}

.shipping_container .nav-link.disabled{
    color: #000000!important;
}

.shipping_container form{
    margin: 10px 0 0 0;
}

.shipping_container form div{
    margin-bottom: 10px;
}

.shipping_container form label{
    color: #000000!important;
    font-size: 1rem;
    text-transform: uppercase;
}

.shipping_container form button{
    background-color: #7e0d10!important;
    margin: 15px 0;
}

.shipping_container form button:hover{
    background-color: #7e0d11ce!important;
}

.shipping_container form .form-check-input:checked{
    background-color: #7e0d11ce;
    border-color: #7e0d11ce;
}

@media(max-width: 991px) {}

@media(max-width: 767px) {
    .shipping_container .container_login_register{
        padding: 15px 30px;
        background-image: none;
    }
}

@media(max-width: 479px) {}