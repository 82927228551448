.border_none{
    border: none!important;
}

.placeorder_container{
    width: 100%;
    margin-top: 50px;
    padding: 0 30px;
    color: #7e0d10;
}

.placeorder_container h2{
    text-align: center;
    color: #7e0d10;
    text-transform: uppercase;
}

.placeorder_container .nav{
    margin: 0!important;
}

.placeorder_container .nav-link{
    color: #7e0d10!important;
    font-size: 1.5rem;
}

.placeorder_container .nav-link.disabled{
    color: #000000!important;
}

/* datos de envio */
.placeorder_subcontainer{
    display: flex;
    width: 100%;
    gap: .5rem;
}

.data_info_container{
    width: 80%;
    padding: 15px 0;
}

.info_container{
    border-top: 1px solid #e4e4e4;
    padding: 15px 0;
}

.info_container span{
    font-weight: 500;
    font-size: 1.5rem;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
}

.info_container p{
    color: #000000;
    margin: 0 0 0 15px;
    line-height: 1.8;
}

/* lista de productos a comprar */
.place_prod_container{
    display: flex;
    flex-direction: column;
}

.place_prod_container .product{
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
}

.product_img_container img{
    width: 8rem;
    height: 8rem;
    border-radius: 15px;
    object-fit: contain;
}

.product_img_container a{
    margin-left: 15px;
    color: #7e0d10!important;
    text-decoration: none;
}

.product_img_container a:hover{
    text-decoration: underline;
}

.product p{
    font-weight: 500;
    margin: 0;
}


/* tarjeta con el resumen el pedido */
.resumen_container{
    width: 20%;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 15px 0;
    height: fit-content;
}

.resumen_container .resumen_orden_titulo{
    border-bottom: 1px solid #e4e4e4;
    padding: 0 15px 20px 15px;
    color: #000000;
    font-size: 1.5rem;
    margin: 0;
}

.resumen_container .resumen_order_desc{
    border-bottom: 1px solid #e4e4e4;
    padding: 15px 20px;
    color: #000000;
    font-size: 1rem;
    margin: 0;
}

.resumen_button_container{
    padding: 15px 20px 0 15px;
    text-align: center;
}

.resumen_button_container button{
    background-color: #7e0d10;
    border: none;
}

.resumen_button_container button:hover,
.resumen_button_container button:active{
    background-color: #7e0d11aa;
}

@media(max-width: 1000px) {
    .placeorder_subcontainer{
        flex-direction: column;
    }

    .data_info_container,
    .resumen_container{
        width: 100%;
    }
}

@media(max-width: 767px) {}

@media(max-width: 479px) {}