.prod_portada{
    padding: 2.5rem 0 1.5rem;
    background-color: #ebebeb;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #D1FFE3;
    color: #7e0d10 !important;
}

 .h3{
    padding: 4.6rem 0 5rem;
    color: #7e0d10 !important;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.producto_container{
    display: flex;
    width: 100%;
    padding: 0 30px;
    margin: 50px auto 0 auto;
    gap: 1rem;
}

.image_container{
    display: flex;
    width: 60%;
    gap: .5rem;
}

.image_container .ref_images_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    gap: .5rem;
}

.menos_de_cuatro{
    justify-content: initial!important;

}

.ref_images_container button{
    width: 10rem;
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
}

.ref_images_container button img{
    width: 100%;
}

.image_container .current_image_container{
    width: 80%;
    height: 80vh;    
}

.current_image_container .current_image{
    width: fit-content;
    height: 100%;
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.detail_container{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
}

.hacer_pedido_button{
    margin: 10px 0;
    background-color: #7e0d10;
}

.hacer_pedido_button:hover,
.hacer_pedido_button:visited,
.hacer_pedido_button:focus{
    background-color: #a2262acc;
    border: none;
}

.detail_container .footer_card{
    padding: .5rem 1rem;
}

@media(max-width: 1500px) {
    .image_container{
        flex-direction: column-reverse;
        width: 50%;
    }
    .image_container .ref_images_container{
        flex-direction: row;
        gap: .3rem;
        width: 100%;
    }
    .detail_container{
        width: 50%;
    }
    .image_container .current_image_container{
        width: 100%;
        height: 60vh;
    }
    .ref_images_container button img{
        height: 15vh;
        width: initial;
    }
}

@media(max-width: 991px) {
    .producto_container{
        flex-direction: column;
    }
    .image_container, .detail_container{
        width: 100%;
    }
}

@media(max-width: 767px) {
    .image_container .current_image_container{
        height: 50vh;
    }
}

@media(max-width: 479px) {}