main{
    min-height: 88vh;
}
h1{
    font-size: 1.8rem;
    padding: 1rem 0;
}
h1{
    font-size: 1.4rem;
    padding: 0.5rem 0;
}
h2{
    color: rgb(245, 244, 244);
}
.imageContainer {
    max-width: 150px;
    max-height: 150px;
    position: relative;
  }
  :root {
    --dl-color-gray-500: #595959;
    --dl-color-gray-700: #999999;
    --dl-color-gray-900: #D9D9D9;
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-danger-300: #A22020;
    --dl-color-danger-500: #BF2626;
    --dl-color-danger-700: #E14747;
    --dl-color-gray-black: #000000;
    --dl-color-gray-white: #FFFFFF;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-color-primary-100: #003EB3;
    --dl-color-primary-300: #0074F0;
    --dl-color-primary-500: #14A9FF;
    --dl-color-primary-700: #85DCFF;
    --dl-color-success-300: #199033;
    --dl-color-success-500: #32A94C;
    --dl-color-success-700: #4CC366;
    --dl-size-size-xxlarge: 288px;
    --dl-size-size-maxwidth: 1400px;
    --dl-radius-radius-round: 50%;
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-space-space-oneandhalfunits: 24px;
  }
  .button {
    color: var(--dl-color-gray-black);
    cursor: pointer;
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 18px;
    box-shadow: 5px 6px 0px 0px #000000;
    font-style: normal;
    transition: 0.3s;
    font-weight: 500;
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 0px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: rgb(255, 207, 119);
  }
  .button:hover {
    opacity: 0.5;
  }
  .input {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem 1rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .textarea {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .list {
    width: 100%;
    margin: 1em 0px 1em 0px;
    display: block;
    padding: 0px 0px 0px 1.5rem;
    list-style-type: none;
    list-style-position: outside;
  }
  .list-item {
    display: list-item;
  }
  .teleport-show {
    display: flex !important;
    transform: none !important;
  }
  .social {
    width: 20px;
    display: flex;
    padding: 0px;
    box-shadow: none;
    align-items: center;
    border-width: 0px;
    border-radius: 0px;
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
  }
  .button-clean {
    border: none;
    padding: 0px;
    box-shadow: none;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: transparent;
  }
  .button-link {
    color: rgb(255, 255, 255);
    display: flex;
    font-size: 24px;
    box-shadow: none;
    font-style: normal;
    align-items: center;
    font-weight: 400;
    line-height: 36px;
    padding-top: var(--dl-space-space-threeunits);
    border-color: rgba(255, 255, 255, 0.3);
    border-width: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
    text-decoration: none;
    background-color: transparent;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  .accordion {
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    border-color: rgba(255, 255, 255, 0.2);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
    border-bottom-width: 1px;
  }
  .accordion:hover {
    color: white;
    border-color: white;
  }
  .Heading {
    font-size: 32px;
    font-family: Inter;
    font-weight: 700;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .Content {
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  
  @media(max-width: 767px) {
    .button-link {
      padding-top: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-oneandhalfunits);
    }
  }
  @media(max-width: 479px) {
    .accordion {
      padding-top: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-oneandhalfunits);
    }
  }
  