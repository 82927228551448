:root{
    --color-scrollbar: #d3d3d4;
    --color-background-scrollbar: #eee;
}

/*Scroll Chrome barra lateral*/
.layout__aside::-webkit-scrollbar{
    width: 25px;
    background: var(--color-secundario);
}

.layout__aside::-webkit-scrollbar-thumb{ 
    border: 10px solid transparent;
    box-shadow: none;
    border-radius: 40px;
}

.layout__aside:hover::-webkit-scrollbar-thumb{
    box-shadow: inset 0 0 0 10px white;
}

.layout__aside::-webkit-scrollbar-track{
    background: transparent;
    margin: 40px 0;
}

/*scroll barra lateral Mozz*/
.layout__aside{
    scrollbar-width: thin;
    scrollbar-color: var(--color-scrollbar) var(--color-secundario);
}

.layout{
    position: relative;
    min-width: 1290px;
    width: 75%;
    height: 80vh; /*Altura con respecto a la pantalla*/
    min-height: 80vh;
    margin: 18vh auto; /*Centra*/

    /*Distribución con grid*/
    display: grid;
    grid-template-areas:
    "aside content";
    grid-template-columns: 
    30% 70%;

    /*Sombra detras del Layout*/
    border-radius: 4rem; /*40px equivalente a los 10 pixeles del body*/
    box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
    /*overflow: hidden;*/
}


/* Media Queries for Responsive Layout */
@media screen and (max-width: 768px) {
    .layout {
      display: initial;
      flex-direction: row;
      position: relative;
      min-width: 1290px;
      width: 75%;
      height: 80vh; /*Altura con respecto a la pantalla*/
      min-height: 80vh;
      margin: 18vh auto; /*Centra*/
      /*Sombra detras del Layout*/
      border-radius: 4rem; /*40px equivalente a los 10 pixeles del body*/
      box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
      /*overflow: hidden;*/
    }
  
    .layout__aside {
        margin-top: 142px;
    }
  
    .layout__content {
        padding-bottom: 300px;
    }
  }
/*Barra lateral azul*/
.layout__aside{
    background-image: linear-gradient(to right, #87B338, #6c9d12);
    grid-area: aside;
    border-radius: 4rem 0 0 4rem;
    overflow: auto;
    overflow-x: hidden;
}

.aside__user-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    min-height: 100%;
    min-width: 25rem;

    background-color: var(--color-secundario);
    color: var(--color-principal);
}

/*Imagen de perfil*/
.user-info__container-imagen{
    position: relative;
    display: inline-block;
    width: 10rem;
    margin-top: 7rem;
    z-index: 1;
}



.user-info__image{
    width: 100%;
    background-color: var(--color-principal);
    border: 3px solid var(--color-principal);
    border-radius: 20rem;
}

.user-info__container-imagen::after{
    content: "";
    position: absolute;
    top: 5%;
    left: 9%;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: -webkit-repeating-radial-gradient(center center, #fff, #fff 1px, transparent 0px, transparent 100%);
    background-size: 0.6rem 0.6rem;
    border-radius: 30rem;
    opacity: 0.6;
}

.user-info__name{
    font-size: 3.2 rem;
    font-weight: bold;
    color: var(--color-principal);
    margin-top: 2.6rem;
}

.user-info__job{
    font-size: 1,7rem;
    font-weight: 200;
    color: var(--color-principal);
}

.user-info__links{
    margin-bottom: 3,6rem;
}

.links__social{
    margin-top: 2rem;
    text-align: center;

    display: flex;
    flex-direction: row;
}


.user-info__buttons{
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.user-info__btn{
    padding: 3rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    border: 2px solid var(--color-principal);
    border-radius: 3.2rem;
    color: var(--color-principal);
    box-shadow: 0 10px 10px -8px rgba(0, 0, 0, 0.22);
    transition: all 300ms ease-in-out;
}

.user-info__btn:hover{
    background-color: var(--color-principal);
    color: var(--color-secundario);
}

.user-info__footer{
    position: absolute;
    bottom: 2rem;
    font-size: 1.2rem;
}

/*Contenedor principal*/
.layout__content{
    grid-area: content;
    height: 100%;
    min-height: 100%;
    background-color: var(--color-secundario);
    border-radius: 0 10rem 10rem 0;
}

.content__page{
    min-height: 100%;
    background-color: var(--color-principal);
    border-radius: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page__name{
    font-size: 6rem;
    margin-bottom: 1rem;
}

.page__job{
    font-size: 2.1rem;
    font-weight: lighter;
    color: var(--color-subtitle);
}

/*Estilos generales (Sobre ti)*/

.content__about{
    overflow: auto; /*Scroll interno*/
    height: 100%;
    min-height: 100%;
    padding: 5rem;
    padding-right: 3rem;
    display: block;
}

.about__header{
    display: inline-block;
    margin-bottom: 2.5rem;
}

.about__title{
    font-size: 2.2rem;
    position: relative;
}

.about__title::after{
    content: "";
    position: absolute;
    top: 20px;
    right: -25px;
    width: 50px;
    height: 30px;
    background-image: -webkit-radial-gradient(center center, var(--color-secundario), var(--color-secundario) 1px, transparent 0px, transparent 100%);
    background-size: 6px 6px;
}

.title__color{
    color: #87b338;
}

/*Estilos de suscripciones*/

.about__extra::after{
    content: "";
    position: absolute;
    top: 15px;
    right: 0;
    width: 30px;
    height: 20px;
    background-image: -webkit-radial-gradient(center center, var(--color-secundario), var(--color-secundario) 1px, transparent 0px, transparent 100%);
    background-size: 6px 6px;
    opacity: 0.5;
}


.extra__info:hover{
    box-shadow: 0 18px 24px -5px rgba(0, 0, 0, 0.15);
    transform: translateY(-1rem);
}

.extra__container{
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.extra__info{
    flex-basis: 30%;
    border: 2px solid var(--color-border-reviews);
    text-align: center;
    border-radius: 1rem;
    padding: 2.5rem 1rem;
    transition: all 300ms ease-in-out;
}

.extra__info:hover{
    transform: translate(-5px);
}

.extra__ico{
    font-size: 3.3rem;
    color: var(--color-secundario);
    margin-bottom: 1rem;
}

.extra__title{
    font-size: 1.5rem;
    margin: 1.5rem;
}

.extra__quantity{
    display: block;
    font-size: 3.6rem;
    line-height: 5rem;
    margin: 1.5rem 1;
    margin-bottom: 0;
    color: var(--color-subtitle);
}