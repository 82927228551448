/* Estilos para el botón de compartir */
.compartir-container {
    position: relative;
    display: inline-block; /* Hace que el botón y el panel compartan el mismo espacio */
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Estilos para el panel emergente */
  .panel-emergente {
    position: sticky;
    top: 10%; /* Coloca el panel justo debajo del botón */
    right: 0;
    background-color: #FFCF77;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    width: 230px; /* Ajusta el ancho según tus necesidades */
  }
  
  .opcion-compartir {
    margin: 5px 0;
    cursor: pointer;
  }
  
  /* Estilos para el logo */
  .logo {
    width: 50px; /* Ajusta el tamaño según tus necesidades */
    height: 50px; /* Ajusta el tamaño según tus necesidades */
  }
  