.work-with-us-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 35vh;
    width: 100%;
    background: linear-gradient(to right, #87B338, #588703);
    padding: 31px;
  }
  
  .work-with-us-content {
    text-align: center;
  }
  
  .work-with-us-title {
    margin-top: 0;
    font-size: 32px;
    font-weight: bold;
    color: white;
  }
  
  .work-with-us-description {
    font-size: 18px;
    color: white;
  }